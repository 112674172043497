<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Ajouter un card icon top</h3>
                        <!-- Importation d'image du card -->
                        <b-form-group label="Importer une image"  label-for="cardImage">
                            <b-form-file v-model="file" id="inpu8" :state="Boolean(file)" @change="onFileSelected" placeholder="Choisir ou glisser l'image ici"></b-form-file>
                        </b-form-group>
                        <p>selected File: {{file ? file.name : ''}}</p>
                        <!-- Titre -->
                        <b-form-group label="Titre du card"  label-for="cardTitle">
                            <b-form-input v-model="formIndividualCard.cardTitle" placeholder="Entrer le titre" ></b-form-input>
                        </b-form-group>
                        <!-- Titre -->
                        <b-form-group label="Description du card"  label-for="cardDescription">
                            <b-form-input v-model="formIndividualCard.cardDescription" placeholder="Entrer la description" ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-link">Annuler</button>
                        <button class="btn btn-primary" @click="submitImageAndCard()">Ajouter le card</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Représentation du card</h3>
                        <span v-for= "block in blocks" :key="block.id">
                            <p>section: {{section}}</p>
                            <p>blocks:{{block}}</p>
                            <p>blocks.data <br>{{block.data}}</p>
                            <p>"Block.data.card": {{block.data["card-icon-top"]}}</p>
                            <cardIconTop 
                            :card="block.data['card-icon-top']"
                            :key="individualCardComponentKey"
                            title="card-title"
                            description="card-description"
                            v-on:edit="editCard"
                            editable
                            />
                        </span>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-link">Annuler</button>
                        <button class="btn btn-gradient-primary" @click="saveCard()">Enregistrer</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import cardIconTop from '../../../components/element/card-icon-top.vue'
export default {
    name: 'card-icon-top',
    components:{
        cardIconTop
    },
    data () {
        return {
            sectionTitle: '',
            section: '',
             formIndividualCard: {
                cardTitle: '',
                cardDescription: ''
            },
            file: null,
            blocks: [],
            individualCardComponentKey : 0
        }
    },
    methods: {
        getSectionInfo (ulid) {
            axios.get(`sections/${ulid}`)
            .then (resSectionInfo => {
                console.log({resSectionInfo})
                this.section = resSectionInfo.data
                this.blocks = resSectionInfo.data.blocks
                console.log('blocks dans getSectionInfo', this.blocks);
                this.sectionTitle = resSectionInfo.data.title
                console.log('section in getSectionInfo', this.section)
                })
            .catch (errSectionInfo => console.log({errSectionInfo}))
        },
        addCard (imageContent) {
            console.log('this section in addCard',this.section);
            let card = this.blocks[0].data['card-icon-top']
            console.log('this.blocks',this.blocks);
            card.push({
                "card-image-url": imageContent,
                "card-title": this.formIndividualCard.cardTitle,
                "card-description": this.formIndividualCard.cardDescription
            })
            this.blocks = [
                {
                    "element" : "card-icon-top",
                    "data": {
                        "card-icon-top": card
                    }
                }
            ]
            console.log('const card: ', card)
            console.log('blocks après add Card', this.blocks);
            this.individualCardComponentKey += 1
            this.saveCard()
        },
        saveCard () {
            axios.put(`sections/${this.ulid}`, {
                "title": this.sectionTitle,
                "blocks" : this.blocks
            },
            {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then (resSaveCard => {
                this.$snotify.success('Card enregistré avec succès', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                })
                console.log({resSaveCard})
                })
            .catch (errSaveCard => console.log(errSaveCard))
        },
        // upload d'image
        onFileSelected (event) {
          this.file=event.target.files[0]
        },
        submitImageAndCard () {
          const fd = new FormData
          fd.append ('imgfile', this.file)
          fd.append ('alt', this.file)
          axios.post('imgs', fd, 
          {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resSubmitImage=> {
            console.log('resSubmitImage',resSubmitImage)
            let imageUrl = resSubmitImage.data.url
            this.addCard(imageUrl)
            })
          .catch(errSubmitImage=> console.log(errSubmitImage))
        },
        editCard(item) {
            console.log('payload',item)
        }
    },
    mounted () {
        this.ulid = this.$route.params.ulid
        this.getSectionInfo(this.ulid)
    }
}
</script>